@import "~bootstrap-scss/bootstrap.scss";
@import "color";
@import "vendors/chartist/chartist";
@import "vendors/themify.scss";
@import "vendors/material-design-icon.scss";
@import "vendors/typicon-icon.scss";
@import "vendors/pe7-icon.scss";
@import "vendors/ionic-icon.scss";
@import "vendors/simple-line-icon.scss";
@import "vendors/whether-icon.scss";
@import "vendors/icofont.scss";
@import "vendors/flag-icon.scss";
@import "vendors/icoicon/icons";
@import "vendors/fontawesome.scss";
@import "vendors/animate/animate";
@import "vendors/sticky/sticky";
@import "vendors/todo/todo";

// Datepicker
@import "~react-datepicker/dist/react-datepicker.css";

// Timepicker
@import "~react-times/css/material/default.css";
@import "~react-times/css/classic/default.css";

// Typeahead
@import "~react-bootstrap-typeahead/css/Typeahead.css";

//  Dropzone
@import "~react-dropzone-uploader/dist/styles.css";

// Drag&Drop
@import "~react-dragula/dist/dragula.css";

@import "~react-input-range/lib/css/index.css";

// ReactToastify
@import "~react-toastify/dist/ReactToastify.css";

//  ImageCrop
@import "~react-image-crop/dist/ReactCrop.css";

//  calendar
@import "~react-big-calendar/lib/sass/styles.scss";
@import "~sweetalert2/src/sweetalert2.scss";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";

//  Timeline
@import "~react-vertical-timeline-component/style.min.css";

// Mde EDitor
@import "~easymde/dist/easymde.min.css";

// chat emoji
@import "~emoji-mart/css/emoji-mart.css";

// Map
@import "~leaflet/dist/leaflet.css";

// Image gallery
@import "~react-image-lightbox/style.css";

.loader-abs {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.data-table-extensions {
  margin-bottom: 10px;
}

.multi-step > ol.progtrckr li.progtrckr-todo[value="3"] {
  display: none;
}

.corner-buttons {
  position: absolute;
  bottom: 25px;
  right: 25px;
}

.form-control.digits.search-datepicker {
  height: 50px !important;
}

.ml-20 {
  margin-left: 20px;
}

.search-hover {
  cursor: pointer;
  &:hover {
    color: #7366ff !important;
  }
}

.chat-user {
  cursor: pointer;
  &:hover {
    background-color: rgba(229, 231, 235, 0.5);
  }
}

.chat-user-active {
  background-color: rgba(229, 231, 235, 0.5);
}

.select__menu {
  z-index: 10000 !important;
}

.react-datepicker-popper {
  z-index: 10000 !important;
}

.radio-controllers {
  position: absolute;
  right: 25px;
  top: 40px;
}

.radio-btn {
  cursor: pointer;
  &:hover {
    fill: #000;
  }
}

.playlist-bdg {
  cursor: pointer;
  margin: 5px;
  &:hover {
    background-color: #d1d1d1;
  }
}

.song {
  position: relative;
  top: 25%;
}

.modal-right {
  padding-right: 0 !important;

  .modal-dialog {
    margin: 0 auto;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    max-width: 380px;
  }

  .modal-content {
    min-height: 100%;
  }

  // .modal-header {
  //   height: $navbar-height;

  //   @include respond-below(xl) {
  //     height: $navbar-height-lg;
  //   }

  //   @include respond-below(lg) {
  //     height: $navbar-height-md;
  //   }

  //   @include respond-below(sm) {
  //     height: $navbar-height-xs;
  //   }
  // }

  .modal-footer {
    justify-content: center;
  }

  .modal.fade .modal-dialog {
    transform: translate(25%, 0) !important;
  }

  .modal.show .modal-dialog {
    transform: translate(0, 0) !important;
  }
}

.reduce-padding {
  padding: 20px !important;
  padding-right: 25px !important;
}

.blog-bottom-content {
  p {
    overflow: hidden !important;
  }
}